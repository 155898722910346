import React from "react";
import _ from "lodash";

import { Layout } from "../components/index";

import HeroTitle from "../components/HeroTitle";

export default class Vouchers extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <section className="page">
          <div className="p-strip is-deep">
            <HeroTitle {...this.props} />

            <div className="u-pre">
              {_.get(
                this.props,
                "pageContext.site.data.vouchers.voucher-intro"
              )}
            </div>

            <div className="form__wrap">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top"
              >
                <fieldset className="u-no-padding--bottom u-no-margin--bottom">
                  <legend>Your voucher details</legend>
                  <input type="hidden" name="cmd" value="_s-xclick"></input>
                  <input
                    type="hidden"
                    name="hosted_button_id"
                    value="BY6HJ4NRL8BBW"
                  ></input>
                  <input type="hidden" name="on0" value="Select amount"></input>
                  <label htmlFor="os0">Voucher value</label>
                  <select name="os0">
                    <option value="30 minute treatment">
                      30 minutes - £25.00
                    </option>
                    <option value="45 minute treatment">
                      45 minutes - £35.00
                    </option>
                    <option value="60 minute treatment">
                      60 minutes - £45.00
                    </option>
                    <option value="90 minute treatment">
                      90 minutes - £65.00
                    </option>
                    <option value="5x 30 minute sessions">
                      5x30 minute sessions - £110.00
                    </option>
                    <option value="5x 60 minute sessions">
                      5x60 minute sessions - £200.00
                    </option>
                  </select>
                  <input
                    type="hidden"
                    name="on1"
                    required
                    value="Name on voucher"
                  />
                  <label htmlFor="os1">Recipient name</label>
                  <input type="text" name="os1" maxLength="200" />
                  <label htmlFor="os2">Delivery address</label>
                  <input type="hidden" name="on2" required value="address" />
                  <textarea name="os2" maxLength="200" />
                  <input type="hidden" name="currency_code" value="GBP" />
                  <div className="u-align--center p-strip is-shallow u-no-margin--bottom u-no-padding--bottom">
                    <input
                      type="image"
                      src="https://ik.imagekit.io/oi85dhs4k/tr:w-200/images/buynow.png"
                      border="0"
                      name="submit"
                      alt="Pay now using Paypal"
                      width="200"
                    />
                  </div>
                  <img
                    alt=""
                    border="0"
                    src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif"
                    width="1"
                    height="1"
                  />
                </fieldset>
              </form>
              <div className="p-strip is-deep">
                <div className="u-pre">
                  <small>
                    {_.get(
                      this.props,
                      "pageContext.site.data.vouchers.voucher-small-print"
                    )}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
